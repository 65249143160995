import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Field } from 'formik'

const Error = loadable(() => import('/src/components/Form/Error'))
const Required = loadable(() =>
    import('/src/components/Form/Required/Required')
)

const TextAreaField = ({
    label,
    name,
    placeholder,
    isSubmitting,
    isSubmitted,
    required
}) => {
    return (
        <div>
            <label htmlFor={name}>
                {label}
                {required && <Required />}
            </label>

            <Field
                as={'textarea'}
                rows={6}
                name={name}
                id={name}
                placeholder={placeholder}
                className={'form-control'}
                disabled={isSubmitting || isSubmitted}
            />

            <Error name={name} />
        </div>
    )
}

TextAreaField.propTypes = {
    /**
     * Field label
     */
    label: PropTypes.string.isRequired,
    /**
     * Field name
     */
    name: PropTypes.string.isRequired,
    /**
     * Field placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Whether field is required
     */
    required: PropTypes.bool
}

TextAreaField.defaultProps = {
    label: 'Message',
    name: 'message',
    placeholder: '',
    isSubmitting: false,
    isSubmitted: false,
    required: false
}

export default TextAreaField
